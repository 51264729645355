import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show"];

  display() {
    if (this.showTarget.classList.contains('hidden')) {
      this.element.classList.add('active')
      this.showTarget.classList.remove('hidden');
    } else {
      this.element.classList.remove('active')
      this.showTarget.classList.add('hidden');
    }
  }
}
