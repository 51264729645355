import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "submit", "password", "passwordConfirmation", "passwordLengthIcon", "nonNumericPresenceIcon", "numberPresenceIcon",
    "specialCharacterPresenceIcon", "passwordMatchIcon", "acknowledge"
  ]

  toggleEditPassword() {
    if (
      (!this.hasAcknowledgeTarget && this.passwordTarget.value.length === 0) ||
      ((!this.hasAcknowledgeTarget || this.acknowledgeTarget.checked) &&
      this.validatesLength() &&
      this.validatesNonNumericPresence() &&
      this.validatesNumberPresence() &&
      this.validatesSpecialCharacterPresence() &&
      this.validatesMatchingPasswords())
    ) {
      this.submitTarget.removeAttribute('disabled')
    }
    else {
      this.submitTarget.setAttribute('disabled', true)
    }
  }

  validates() {
    this.setIcon(this.validatesLength(), this.passwordLengthIconTarget)
    this.setIcon(this.validatesNonNumericPresence(), this.nonNumericPresenceIconTarget)
    this.setIcon(this.validatesNumberPresence(), this.numberPresenceIconTarget)
    this.setIcon(this.validatesSpecialCharacterPresence(), this.specialCharacterPresenceIconTarget)
    this.setIcon(this.validatesMatchingPasswords(), this.passwordMatchIconTarget)
  }

  validatesLength() {
    return this.passwordTarget.value.length >= 10
  }

  validatesNonNumericPresence() {
    return /[a-zA-Z]/.test(this.passwordTarget.value)
  }

  validatesNumberPresence() {
    return /\d/.test(this.passwordTarget.value)
  }

  validatesSpecialCharacterPresence() {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

    return specialCharacters.test(this.passwordTarget.value)
  }

  validatesMatchingPasswords() {
    return this.passwordTarget.value.length > 0 && this.passwordTarget.value === this.passwordConfirmationTarget.value
  }

  setIcon(valid, target) {
    let icons = ['fa-circle-check', 'fa-circle-xmark']
    let classes = ['requirement-success', 'requirement-fails']

    if (!valid) {
      icons = icons.reverse()
      classes = classes.reverse()
    }

    target.classList.add(icons[0])
    target.classList.remove(icons[1])
    target.parentElement.classList.add(classes[0])
    target.parentElement.classList.remove(classes[1])
  }

  toggleVisibility(event) {
    const icon = event.target;
    const input = icon.parentElement.previousElementSibling;

    if (input.type === 'password') {
      input.type = 'text'
      icon.classList.remove('fa-eye-slash')
      icon.classList.add('fa-eye')
    }
    else {
      input.type = 'password'
      icon.classList.remove('fa-eye')
      icon.classList.add('fa-eye-slash')
    }
  }
}

